
/* Global Styles */
body {
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  font-family: 'Arial', sans-serif;
}

a {
  color: #BE5D8F; /* Red links */
  text-decoration: none;
}

a:hover {
  color: #D677A2; /* Light red links on hover */
}

/* Navbar Styles */
.navbar {
  background-color: #000; /* Black navbar */
  border-bottom: 1px solid #BE5D8F; /* Red bottom border */
}

.navbar-brand, .navbar-nav .nav-link {
  color: #fff; /* White text */
}

.navbar-brand:hover, .navbar-nav .nav-link:hover {
  color: #BE5D8F; /* Red text on hover */
}

/* Button Styles */
.btn-primary {
  background-color: #BE5D8F; /* Red buttons */
  border: none;
}

.btn-primary:hover {
  background-color: #D677A2; /* Light red on hover */
}

.btn-neutral {
  background-color: #BE5D8F; /* Same as btn-primary but more muted */
  border: 1px solid #BE5D8F;
  color: #fff;
  opacity: 0.8; /* Makes it more neutral */
}

.btn-neutral:hover {
  background-color: #D677A2; /* Same hover as btn-primary but more muted */
  color: #fff;
  opacity: 0.8;
}

/* Card Styles */
.card {
  background-color: #1c1c1c; /* Dark grey card background */
  border: 1px solid #333; /* Slightly lighter border */
}

.card-header, .card-footer {
  background-color: #BE5D8F; /* Red header and footer */
  color: #fff;
}

.card-title, .card-text {
  color: #fff; /* White text in cards */
}

/* Form Styles */
.form-control {
  background-color: #333; /* Dark grey form background */
  color: #fff; /* White text */
  border: 1px solid #555; /* Slightly lighter border */
}

.form-control:focus {
  border-color: #BE5D8F; /* Red border on focus */
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25); /* Red shadow on focus */
}

/* Footer Styles */
.footer {
  background-color: #000; /* Black footer */
  color: #fff; /* White text */
  border-top: 1px solid #BE5D8F; /* Red top border */
  padding: 20px;
  text-align: center;
}

/* Additional Styles */
h1, h2, h3, h4, h5, h6 {
  color: #BE5D8F; /* Red headings */
}

.table {
  color: #fff; /* White text in tables */
}

.table thead th {
  background-color: #BE5D8F; /* Red table header */
  color: #fff;
}

.table tbody tr:nth-child(even) {
  background-color: #1c1c1c; /* Dark grey alternate row */
}

.alert {
  background-color: #BE5D8F; /* Red alerts */
  color: #fff;
  border: none;
}

.stronger {
  font-weight: bolder;
}

.header-text {
  color: #65BDC2 !important;
}

.header-text:hover {
  color: #539a9e !important;
}

.message-text-color {
  color: #BE5D8F;
}

.custom-input {
  /* Styles for the unfocused state */
  border-color: #333; /* Default border color */
  background-color: #fff; /* Default background color */
  color: #000; /* Default text color */
  border: 1px solid #555; /* Slightly lighter border */
}

.custom-input:focus {
  /* Styles for the focused state */
  border-color: #BE5D8F; /* Border color on focus */
  background-color: #fff; /* Background color on focus */
  color: #000; /* Text color on focus */
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25); /* Red shadow on focus */
  outline: none; /* Remove default outline */
}
